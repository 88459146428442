.Footer{
    font-size:10pt !important;
    color:#fff;
    padding:10px;
    margin-top:5px;
    min-height:80px;
    border-radius: .25em;
    border-color:grey;
    background-color: rgb(37, 37, 37);  
    margin-bottom: 10px;
 }

 .Footer .col{
     text-align:left;
 }

 .Footer .row{
     padding-top:2px;
 }