  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #ffffff;
  }
  .tab-content{
    border-style:solid;
    border-color:#eee;
    border-width:0px 1px 1px 1px;
    padding:5px;
  }
  
.multiple-select{
  font-size: 10pt !important;
}


.button-container{
  width:100%;
  text-align:center;
}